import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetAllLocations } from "../../apiCalls/LocationCalls";
import { GetCompany } from "../../apiCalls/CompanyCalls";
import { setAllCompanyInfo } from "../../redux/reducers/CompanyReducer";
import { setAllLocationInfo } from "../../redux/reducers/LocationReducer";
import { setAllUserInfo } from "../../redux/reducers/UserReducer";
import { setScreen } from "../../redux/reducers/PageReducer";
import { useNavigate } from "react-router-dom";

const LocationSelection = () => {
  const [locations, setLocations] = useState([]);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useNavigate();

  const locationIds = useMemo(() => {
    if (!user?.location_id) return [];
    return user.location_id.split(",");
  }, [user?.location_id]);

  useEffect(() => {
    const fetchLocations = async () => {
      if (!user?.company_id) return;
      try {
        const res = await GetAllLocations("company", user.company_id);
        if (res?.data) {
          const filteredLocations = res.data.filter((location) =>
            locationIds.includes(location.id.toString())
          );
          setLocations(filteredLocations);
        }
      } catch (error) {
        console.error("Error loading locations:", error);
      }
    };

    if (locationIds.length) {
      fetchLocations();
    }
  }, [locationIds, user?.company_id]);

  const onLocationSelect = (location) => async () => {
    try {
      dispatch(setAllLocationInfo(location));
      const companyRes = await GetCompany(`${location.company_id}`);
      if (companyRes && companyRes.data) {
        // Update user with selected location ID
        const updatedUser = { ...user, location_id: location.id.toString() };
        console.log(updatedUser);

        dispatch(setAllCompanyInfo(companyRes.data));
        dispatch(setAllLocationInfo(location));
        console.log(location);
        dispatch(setScreen("dashboard"));
        dispatch(setAllUserInfo(updatedUser));
        console.log("User info updated:", updatedUser);
        history("/dashboard");
      }
    } catch (error) {
      console.error("Error loading company:", error);
    }
  };

  return (
    <div className="bg-topo-light fullscreen">
      <div className="location-selection">
        <h2>Location Selection</h2>
        <ul>
          {locations.map((location) => (
            <li key={location.id}>
              <button
                className="button button-blue"
                onClick={onLocationSelect(location)}
              >
                {location.name} - {location.address}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LocationSelection;
